import React from 'react';
import Alert from 'react-bootstrap/Alert';

const ErrorAlert: React.FC = () => (
  <Alert variant="danger" >
    <Alert.Heading>Error</Alert.Heading>
    <p>
      There was an error loading the page, please try again later.
    </p>
  </Alert>
);

export default ErrorAlert;