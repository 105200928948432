import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Stack from 'react-bootstrap/Stack';
import { Link } from "react-router-dom";

const RarityRankingsSection = () => (
  <Container>
    <Row>
      <Col>
        <h1 className="text-center mt-5 mb-4">Rarity Rankings of your favorite NFTs</h1>
      </Col>
    </Row>
    <Row className="justify-content-md-center">
      <Col md="3" />
      <Col md="6">
        <p>Browse and explore through an extensive catalogue of NFT collections to find the rarest items on each collection.</p>
        <p>Add a NFT collection and start using the rarity tools available at your disposal to discover the most special collectibles.</p>
      </Col>
      <Col md="3" />
    </Row>
    <Row>
      <Stack className="justify-content-center mt-3" direction="horizontal" gap={3}>
        <Button as={Link as any} to="/get-listed" variant="primary">List your collection</Button>
        <Button href="https://discord.gg/emHXfCkhrD" target="_blank" variant="outline-primary">Join Discord</Button>
      </Stack>
    </Row>
  </Container>
);

export default RarityRankingsSection;
