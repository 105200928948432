import React from 'react';
import { flow, makeObservable, observable } from 'mobx';

export class CollectionStore {
  public collection: TokenCollectionType | undefined;
  public filters: CollectionFilterType[] | undefined;
  public isError = false;
  public isLoading = true;

  constructor() {
    makeObservable(this, {
      collection: observable,
      filters: observable,
      isError: observable,
      isLoading: observable,
      fetchCollection: flow.bound
    });
  }

  public *fetchCollection(id: string): any {
    this.isLoading = true;
    const response = yield fetch(`${process.env.REACT_APP_SERVER_ENDPOINT}/v1/collection/${id}`)
      .then(res => res.json())
      .catch(this.handleError);
    this.collection = response.data.collection;
    this.filters = response.data.filters;
    this.isLoading = false;
  };

  private handleError = (error: any) => {
    this.isError = true;
    this.isLoading = false;
  }
};

interface CollectionStoreProviderProps {
  store: CollectionStore;
};

const CollectionStoreContext = React.createContext<CollectionStore>(Object.create(null));

export const CollectionStoreProvider: React.FC<CollectionStoreProviderProps> = ({ children, store }) => (
  <CollectionStoreContext.Provider value={store} >
    {children}
  </CollectionStoreContext.Provider>
);

export const useCollectionStore = () => React.useContext(CollectionStoreContext);
