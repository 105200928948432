import React from 'react';
import Card from 'react-bootstrap/Card';
import TokenAttributesModal from './TokenAttributesModal';

interface TokenCardProps {
  token: TokenType;
}

const TokenCard: React.FC<TokenCardProps> = ({ token }) => {
  const [modalShow, setModalShow] = React.useState(false);
  const image = token.tokenImage.preview;

  return (
    <>
      <Card className="token-card align-self-stretch" onClick={() => setModalShow(true)}>
        {image && <Card.Img variant="top" src={image} />}
        <Card.Body className="d-flex flex-column">
          <Card.Title>{token.name}</Card.Title>
          <Card.Subtitle className="mb-2 text-muted">Rarity score: {token.rarityScore}</Card.Subtitle>
        </Card.Body>
      </Card>

      <TokenAttributesModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        token={token}
      />
    </>
  );
};

export default TokenCard;
