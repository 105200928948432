import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Placeholder from 'react-bootstrap/Placeholder';

const CollectionBannerLoading: React.FC = () => (
  <Container>
    <Row>
      <Col>
        <Placeholder className="mt-5" as="p" animation="glow">
          <Placeholder xs={5} size="lg" />
        </Placeholder>
        <Placeholder as="p" animation="glow">
          <Placeholder xs={3} size="sm" />
        </Placeholder>
      </Col>
    </Row>
  </Container>
);

export default CollectionBannerLoading;