import React, { useCallback, useContext, useState } from 'react';
import { GlobalToastDataType, GlobalToastIdType } from '../../../types/GlobalToastDataType';
import GlobalToastContainer from './GlobalToastContainer';

type GlobalAddToastDataType = Omit<GlobalToastDataType, 'id'>;
interface GlobalToastProviderProps { };
interface DefaultToastContext {
  addToast: (value: GlobalAddToastDataType) => void;
  deleteToast: (id: GlobalToastIdType) => void;
};

const ToastContext = React.createContext<DefaultToastContext>(Object.create(null));

let id = 0;

const GlobalToastProvider: React.FC<GlobalToastProviderProps> = ({ children }) => {
  const [toasts, setToasts] = useState<GlobalToastDataType[]>([]);

  const addToast = useCallback((data: GlobalAddToastDataType) => {
    setToasts(toasts => [
      ...toasts,
      {
        ...data,
        id: id++
      }
    ]);
  }, [setToasts]);

  // TODO: Remove toast after being dismissed
  const deleteToast = useCallback((id: GlobalToastIdType) => {
    setToasts(toasts => toasts.filter(toast => toast.id !== id));
  }, [setToasts]);

  return (
    <ToastContext.Provider value={{ addToast, deleteToast }}>
      <GlobalToastContainer toasts={toasts} />
      {children}
    </ToastContext.Provider>
  )
};

const useGlobalToast = () => useContext(ToastContext);

export { useGlobalToast };

export default GlobalToastProvider;