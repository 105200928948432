import React, { useCallback, useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import { useGlobalToast } from '../global/toasts/GlobalToastProvider';
import ErrorAlert from '../common/ErrorAlert';

const GetListedForm = () => {
  const [loading, setLoading] = useState(true);
  const [provider, setProvider] = useState('');
  const [tokensLimit, setTokensLimit] = useState(0);
  const [error, setError] = useState(false);

  const [openseaUrlForm, setOpenseaUrlForm] = useState('');
  const [tagsForm, setTagsForm] = useState('');
  const [emailForm, setEmailForm] = useState('');
  const [withAttributesCountForm, setWithAttributesCountForm] = useState(true);
  const [loadingForm, setLoadingForm] = useState(false);

  const { addToast } = useGlobalToast();

  useEffect(() => {
    fetch(`${process.env.REACT_APP_SERVER_ENDPOINT}/v1/get-listed`)
      .then(response => response.json())
      .then(({ data }) => {
        if (data.providers && data.tokensLimit) {
          setProvider(data.providers[0]);
          setTokensLimit(data.tokensLimit);
        } else {
          throw new Error('Invalid API response');
        }
      })
      .catch((error) => {
        console.error(error);
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    console.log({
      provider,
      openseaUrlForm,
      tagsForm,
      emailForm,
    });
    const tagsArray = tagsForm.length ? tagsForm.split(',').map((d: string) => d.trim().replace(/ /g, '-')) : [];
    const openseaUrlLastIndexOf = openseaUrlForm.lastIndexOf('/');
    let collectionSlug = openseaUrlForm;

    if (openseaUrlLastIndexOf >= 0) {
      collectionSlug = openseaUrlForm.substring(openseaUrlLastIndexOf + 1)
    }

    const data = {
      providerName: provider,
      tags: tagsArray,
      data: {
        slug: collectionSlug,
        ...(emailForm && { email: emailForm })
      },
      rarityOptions: {
        withAttributesCount: withAttributesCountForm
      }
    };

    const requestObject: RequestInit = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    };

    setLoadingForm(true);

    fetch(`${process.env.REACT_APP_SERVER_ENDPOINT}/v1/collection`, requestObject)
      .then(response => response.json())
      .then(({ status, message }) => {
        const isSuccess = status === 'success';
        const toastTitle = isSuccess ? 'Succesful' : 'Error';
        const variant = isSuccess ? 'success' : 'danger';
        addToast({
          title: toastTitle,
          message,
          variant
        });
        if (isSuccess) {
          setOpenseaUrlForm('');
          setTagsForm('');
          setEmailForm('');
        }
      })
      .catch(error => {
        addToast({
          title: 'Error',
          message: 'There was an error, please try again later.',
          variant: 'danger'
        });
      })
      .finally(() => {
        setLoadingForm(false);
      });
  };

  const handleOpenseaUrlFormChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setOpenseaUrlForm(value);
  }, []);

  const handleTagsFormChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setTagsForm(value);
  }, []);

  const handleEmailFormChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setEmailForm(value);
  }, []);


  return (
    <Container>
      <Row>
        <Col />
        <Col lg={8}>
          {loading && (
            <div className="text-center">
              <Spinner animation="border" role="status" />
            </div>
          )}
          {error && (<ErrorAlert />)}
          {!loading && !error && (
            <Form onSubmit={handleSubmit}>
              <Form.Group as={Row} className="mb-3" controlId="openseaUrlInput">
                <Form.Label column sm={2} className="fw-bold">
                  Opensea URL
                </Form.Label>
                <Col sm={10}>
                  <Form.Control
                    type="text"
                    placeholder={`${process.env.REACT_APP_OPENSEA_BASE_URL}/collection/`}
                    value={openseaUrlForm}
                    onChange={handleOpenseaUrlFormChange} />
                  <Form.Text muted>
                    Required.
                  </Form.Text>
                </Col>
              </Form.Group>

              <fieldset>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label as="legend" className="fw-bold" column sm={2}>
                    Include attributes count?
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Check
                      defaultChecked
                      type="radio"
                      label="Yes"
                      name="addAttributesCountInput"
                      id="yesAddAttributesCountInput"
                      onChange={() => setWithAttributesCountForm(true)}
                    />
                    <Form.Check
                      type="radio"
                      label="No"
                      name="addAttributesCountInput"
                      id="noAddAttributesCountInput"
                      onChange={() => setWithAttributesCountForm(false)}
                    />
                  </Col>
                </Form.Group>
              </fieldset>

              <Form.Group as={Row} className="mb-3" controlId="collectionTagsInput">
                <Form.Label column sm={2} className="fw-bold">
                  Tags
                </Form.Label>
                <Col sm={10}>
                  <Form.Control
                    type="text"
                    value={tagsForm}
                    onChange={handleTagsFormChange} />
                  <Form.Text muted>
                    Optional. You can add up to 5 tags, separated by a comma.
                  </Form.Text>
                </Col>
              </Form.Group>

              <Form.Group as={Row} className="mb-3" controlId="emailInput">
                <Form.Label column sm={2} className="fw-bold">
                  Your email
                </Form.Label>
                <Col sm={10}>
                  <Form.Control
                    type="email"
                    value={emailForm}
                    onChange={handleEmailFormChange} />
                  <Form.Text muted>
                    Optional.
                  </Form.Text>
                </Col>
              </Form.Group>

              <Form.Group as={Row} className="mb-3">
                <Col sm={2} className="fw-bold">
                  You agree with the following
                </Col>
                <Col sm={10}>
                  <ul>
                    <li>Your collection is no larger than {tokensLimit} items (if it is larger, only the first {tokensLimit} elements will be added)</li>
                    <li>All of the items have been already revealed</li>
                    <li>Attributes are static (they won't change after submiting your collection)</li>
                  </ul>
                  <p>If you need any further changes to your collection after uploading it to Rankings.tools, you will need to request an update.</p>
                </Col>
              </Form.Group>

              <Form.Group as={Row} className="mb-3">
                <Col sm={{ span: 10, offset: 2 }}>
                  {!loadingForm && (
                    <Button type="submit" disabled={!openseaUrlForm}>
                      Submit collection
                    </Button>
                  )}
                  {loadingForm && (
                    <Button type="submit" disabled>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      <span className="ms-1">Loading...</span>
                    </Button>
                  )}
                </Col>
              </Form.Group>
            </Form>
          )}
        </Col>
        <Col />
      </Row>
    </Container>
  );
}

export default GetListedForm;
