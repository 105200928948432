import React, { useEffect } from 'react';
import { Outlet, useParams } from "react-router-dom";
import CollectionBanner from '../components/collection/CollectionBanner';
import { useCollectionStore } from '../stores/CollectionStore';
import { observer } from 'mobx-react-lite';
import CollectionBannerLoading from '../components/collection/CollectionBannerLoading';
import Spinner from 'react-bootstrap/Spinner';
import ErrorAlert from '../components/common/ErrorAlert';

const CollectionPage = () => {
  const params = useParams();
  const { fetchCollection, collection, isLoading, isError } = useCollectionStore();

  useEffect(() => {
    if (params.id) {
      fetchCollection(params.id);
    }
  }, [params.id, fetchCollection])

  return (
    <>
      {isLoading && (
        <div className="TokensSection LoadingTokensSection d-flex flex-column">
          <header className="shadow-sm">
            <CollectionBannerLoading />
          </header>
          <section className="m-auto">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </section>
        </div>
      )}
      {!isLoading && collection && (
        <div className="TokensSection pb-5">
          <header className="shadow-sm">
            <CollectionBanner collection={collection} />
          </header>
          <section className="mt-4">
            <Outlet />
          </section>
        </div>
      )}
      {!isLoading && isError && (
        <ErrorAlert />
      )}
    </>
  );
};

export default observer(CollectionPage);