import React from 'react';
import { createPortal } from 'react-dom';
import ToastContainer from 'react-bootstrap/ToastContainer';
import GlobalToast from './GlobalToast';
import { GlobalToastDataType } from '../../../types/GlobalToastDataType';

interface GlobalToastContainerProps {
  toasts: GlobalToastDataType[]
};

const GlobalToastContainer = ({ toasts }: GlobalToastContainerProps) => (
  createPortal(
    <ToastContainer className="p-3 position-fixed" position="bottom-end">
      {toasts.map((toast, index) => (
        <GlobalToast key={`toast-id-${index}`} {...toast} />
      ))}
    </ToastContainer>,
    document.body
  )
);

export default GlobalToastContainer;