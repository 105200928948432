import React, { useCallback, useState } from 'react';
import Toast from 'react-bootstrap/Toast';
import { GlobalToastDataType } from '../../../types/GlobalToastDataType';

type GlobalToastProps = GlobalToastDataType;

const GlobalToast: React.FC<GlobalToastProps> = (props) => {
  const [show, setShow] = useState(true);

  const handleClose = useCallback(() => {
    setShow(false);
  }, []);

  return (
    <Toast onClose={handleClose} show={show} delay={5000} autohide bg={props.variant || 'light'} >
      <Toast.Header closeButton={false}>
        <strong className="me-auto">{props.title}</strong>
        {props.extra && <small>{props.extra}</small>}
      </Toast.Header>
      <Toast.Body>{props.message}</Toast.Body>
    </Toast>
  );
}

export default GlobalToast;