import React from 'react';
import './Collection.css';
import Image from 'react-bootstrap/Image';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import { Link, useParams } from 'react-router-dom';

interface CollectionBannerProps {
  collection: TokenCollectionType;
}

const CollectionBanner: React.FC<CollectionBannerProps> = ({ collection }) => {
  const params = useParams();
  const baseUrl = `/collection/${params.id}`;
  const openseaCollectionURL = `${process.env.REACT_APP_OPENSEA_BASE_URL}/collection/${collection.id}`;

  return (
    <>
      {
        collection.banner && <div className="collection-banner"><Image src={collection.banner} /></div>
      }
      <Container>
        <Row>
          <Col sm={12} lg={8}>
            {
              collection.image && (
                <div className="collection-image p-2"><Image roundedCircle src={collection.image} /></div>
              )
            }
            <div className="collection-details">
              <h1>{collection.name}</h1>
              {collection.status === 'Syncing' && (
                <p>
                  <span className="me-1">Syncing...</span>
                  <Spinner size="sm" animation="border" role="status" />
                </p>
              )}
              {collection.status !== 'Syncing' && (
                <p>{collection.totalTokens} items</p>
              )}
            </div>
          </Col>
          <Col sm={12} lg={4}>
            <section className="social-links mt-1 flex-wrap">
              <Button variant="link" size="sm" href={openseaCollectionURL} target="_blank">Opensea</Button>
              {
                collection.socialLinks.map((socialLink, index) => (
                  <Button key={`social-link-${index}`} variant="link" size="sm" href={socialLink.url} target="_blank">
                    {socialLink.name}
                  </Button>
                ))
              }
            </section>
          </Col>
        </Row>
        <hr className="mt-3 mb-1" />
        <Row>
          <Col>
            <Nav className="mb-1" defaultActiveKey={baseUrl} as="ul">
              <Nav.Item as="li">
                <Nav.Link as={Link} to={baseUrl}>Tokens</Nav.Link>
              </Nav.Item>
              {
                collection.description && (
                  <Nav.Item as="li">
                    <Nav.Link as={Link} eventKey="link-1" to={`${baseUrl}/about`}>About</Nav.Link>
                  </Nav.Item>
                )
              }
              <Nav.Item as="li">
                <Nav.Link as={Link} to={`${baseUrl}/analytics`}>Analytics</Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default CollectionBanner;