import React from 'react';
import HomeCarousel from '../components/home/HomeCarousel';
import RarityRankingsSection from '../components/home/RarityRankingsSection';
import RecentlyAddedSection from '../components/home/RecentlyAddedSection';
import NewsletterSection from '../components/home/NewsletterSection';

const HomePage = () => {
  return (
    <main className="pb-5">
      <HomeCarousel />
      <RarityRankingsSection />
      <RecentlyAddedSection />
      <NewsletterSection />
    </main>
  );
};

export default HomePage;
