import { observer } from 'mobx-react-lite';
import React from 'react';
import { useCollectionStore } from '../../../stores/CollectionStore';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import ReactMarkdown from 'react-markdown';


const CollectionAbout: React.FC = () => {
  const { collection } = useCollectionStore();
  const description = collection?.description;

  return (
    <Container>
      <Row>
        <Col>
          <Card body>
            {description && (<ReactMarkdown className="mt-3">{description}</ReactMarkdown>)}
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default observer(CollectionAbout);