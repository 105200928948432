import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Figure from 'react-bootstrap/Figure';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';

interface TokenAttributesModalProps {
  show: boolean;
  onHide: () => void;
  token: TokenType;
}

const TokenAttributesModal: React.FC<TokenAttributesModalProps> = ({ token, show, onHide }) => {
  const url = `${process.env.REACT_APP_OPENSEA_BASE_URL}/assets/${token.blockchainMeta.contract.address}/${token.id}`;

  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {token.name}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Col xs={12}>
              {
                token.tokenImage.image && (
                  <Figure>
                    <Figure.Image
                      fluid
                      className="w-100 h-100"
                      src={token.tokenImage.image}
                    />
                    {
                      token.description && (
                        <Figure.Caption>
                          {token.description}
                        </Figure.Caption>
                      )
                    }
                  </Figure>
                )
              }
            </Col>
            <Col xs={12}>
              <span>Rarity score</span>
              <Card className="text-center fs-3 fw-bold" body>{token.rarityScore}</Card>
            </Col>
          </Row>
          <Row>
            <Table responsive>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Value</th>
                  <th>Score</th>
                </tr>
              </thead>
              <tbody>
                {
                  token.attributes.map((attr, index) => (
                    <tr className={attr.type !== 'SimpleAttribute' ? 'fst-italic' : ''} key={`attribute-${token.id}-${index}`}>
                      <td>{attr.key}</td>
                      <td>{attr.type === 'NoAttribute' ? `No ${attr.key}` : attr.value}</td>
                      <td>{attr.rarity.score}</td>
                    </tr>
                  ))
                }
              </tbody>
            </Table>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button href={url} variant="primary" target="_blank">View on opensea</Button>
      </Modal.Footer>
    </Modal>
  );
}

TokenAttributesModal.displayName = 'TokenAttributesModal';
export default TokenAttributesModal;