import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { Link } from 'react-router-dom';

const HomeCarousel = () => (
  <Carousel>
    <Carousel.Item>
      <img
        className="d-block w-100 h-100"
        src="/assets/Banner1.png"
        alt="List your collection today on rankings tools"
      />
      <Carousel.Caption className="text-white" as={Link} to="/get-listed">
        <h3>List your collection today</h3>
        <p>You can list your collection today on Rankings.Tools. It is easy and totally free!</p>
      </Carousel.Caption>
    </Carousel.Item>
    <Carousel.Item>
      <img
        className="d-block w-100 h-100"
        src="/assets/Banner2.png"
        alt="Fast Food Apes Club collection"
      />

      <Carousel.Caption>
        <a className="text-white" href="https://fastfoodapes.club" target="_blank" rel="noopener noreferrer">
          <h3>Fast Food Apes Club</h3>
          <p>The FFAC is here, grab yours now!</p>
        </a>
      </Carousel.Caption>
    </Carousel.Item>
    <Carousel.Item>
      <img
        className="d-block w-100 h-100"
        src="/assets/Banner3.png"
        alt="CryptoMichis collection"
      />

      <Carousel.Caption>
        <a className="text-body" href="https://cryptomichis.com" target="_blank" rel="noopener noreferrer">
          <h3>CryptoMichis</h3>
          <p>The most mischievous collection! Get yours now!</p>
        </a>
      </Carousel.Caption>
    </Carousel.Item>
  </Carousel>
);

export default HomeCarousel;
