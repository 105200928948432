import React from 'react';
import Form from 'react-bootstrap/Form';

interface FiltersProps {
  filters?: CollectionFilterType[];
  onFiltersChange: (checked: boolean, filterKey: string) => void;
}

const Filters: React.FC<FiltersProps> = ({ filters, onFiltersChange }) => {
  const handleFilterChange = (option: string, item: CollectionFiterItemType) => (event: React.ChangeEvent<HTMLInputElement>) => {
    onFiltersChange(event.target.checked, event.target.value);
  };

  return (
    <Form>
      <h3 className="mb-4">Filter by</h3>
      {filters?.map((option, index) => (
        <div key={`filtering-options-${index}`}>
          <h5 className={option.type === 'AttributesCount' ? 'fst-italic mt-3' : 'mt-3'}>
            {option.name} ({option.tokensFilterCount})
          </h5>
          {
            option.items.map((item, i) => (
              <Form.Check
                className={item.type !== 'SimpleAttribute' ? 'fst-italic' : ''}
                key={`filtering-options-item-${index}-${i}`}
                value={item.filterKey}
                type='checkbox'
                id={`filtering-options-item-${index}-${i}`}
                label={item.type === 'NoAttribute' ? `No ${option.name} (${item.tokensAttributeCount})` : `${item.name} (${item.tokensAttributeCount})`}
                onChange={handleFilterChange(option.name, item)}
              />
            ))
          }
        </div>
      ))}
    </Form>
  );
}

export default Filters;