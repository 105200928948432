import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import HomePage from './routes/home-page';
import CollectionPage from './routes/collection-page';
import GetListedPage from './routes/get-listed-page';
import GlobalToastProvider from './components/global/toasts/GlobalToastProvider';
import CollectionAbout from './components/collection/about/CollectionAbout';
import { CollectionStoreProvider, CollectionStore } from './stores/CollectionStore';
import TokensSection from './components/collection/tokens/TokensSection';
import RarityDistributionPage from './components/collection/rarity-distribution/RarityDistributionPage';

ReactDOM.render(
  <React.StrictMode>
    <GlobalToastProvider>
      <CollectionStoreProvider store={new CollectionStore()}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<App />}>
              <Route path="" element={<HomePage />} />
              <Route path="collection" element={<CollectionPage />} >
                <Route path=':id' element={<TokensSection />} />
                <Route path=":id/about" element={<CollectionAbout />} />
                <Route path=":id/analytics" element={<RarityDistributionPage />} />
              </Route>
              <Route path="get-listed" element={<GetListedPage />} />
              <Route
                path="*"
                element={
                  <main style={{ padding: "1rem" }}>
                    <p>There's nothing here!</p>
                    <Link to="/">Go back to home</Link>
                  </main>
                }
              />
            </Route>
          </Routes>
        </BrowserRouter>
      </CollectionStoreProvider>
    </GlobalToastProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
