import React from 'react';
import TokenCard from './TokenCard';
import Stack from 'react-bootstrap/Stack';

interface TokenCardContainerProps {
  tokens: TokenType[];
}

const TokenCardContainer: React.FC<TokenCardContainerProps> = ({ tokens }) => (
  <Stack className="flex-wrap justify-content-start" direction="horizontal" gap={3}>
    {tokens.map((token, index) => (
      <TokenCard token={token} key={`token-card-id-${index}`} />
    ))}
  </Stack>
)

export default TokenCardContainer;