import React from 'react';
import './App.css';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import { Outlet, Link } from "react-router-dom";

const App: React.FC = () => (
  <div className="App">
    <Navbar bg="primary" variant="dark" expand="lg">
      <Container fluid>
        <Navbar.Brand as={Link} to="/">Rankings Tools</Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="me-auto my-2 my-lg-0"
            style={{ maxHeight: '100px' }}
            navbarScroll
          >
            <Nav.Link as={Link} to="/get-listed">List your collection</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    <Outlet />
    <Container fluid>
      <Row className="bg-primary bg-gradient bg-opacity-50">
        <Col className="mt-4" sm={12} lg={6}>
          <span className="fs-2 fw-bold">Rankings.Tools</span>
        </Col>
        <Col className="mt-4 footer-links-section" sm={6} lg={3}>
          <h5>Helpful Links</h5>
          <ul>
            <li><Link to="/">Home</Link></li>
            <li><Link to="/get-listed">List your collection</Link></li>
          </ul>
        </Col>
        <Col className="mt-4 footer-links-section" sm={6} lg={3}>
          <h5>Follow us</h5>
          <ul>
            <li><a href="https://t.me/rankingstools">Telegram</a></li>
            <li><a href="https://discord.gg/emHXfCkhrD">Discord</a></li>
            <li><a href="https://twitter.com/Rankings_Tools">Twitter</a></li>
          </ul>
        </Col>
      </Row>
      <Row>
        <Col className="text-center">Rankings Tools © 2022</Col>
      </Row>
    </Container>
  </div>
);

export default App;
