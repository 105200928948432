import React from 'react';
import GetListedBanner from '../components/get-listed/GetListedBanner';
import GetListedForm from '../components/get-listed/GetListedForm';

const GetListedPage = () => (
  <main className="pb-5">
    <GetListedBanner />
    <GetListedForm />
  </main>
);

export default GetListedPage;