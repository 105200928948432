import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Stack from 'react-bootstrap/Stack';

const NewsletterSection = () => (
  <Container>
    <Row>
      <Col md="3" />
      <Col md="6" className="text-center">
        <h1 className="mt-5 mb-4">Join the telegram newsletter</h1>
        <p>Get the latest news right from your phone. Join now the thelegram newsletter to stay on top of the latest updates.</p>
        <Stack className="justify-content-center mt-3" direction="horizontal" gap={3}>
          <Button href="https://t.me/rankingstools" target="_blank" variant="primary">Join Telegram</Button>
          <Button href="https://twitter.com/Rankings_Tools" target="_blank" variant="outline-primary">Follow us on Twitter</Button>
        </Stack>
      </Col>
      <Col md="3" />
    </Row>
  </Container>
);

export default NewsletterSection;
