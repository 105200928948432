export const getLastItemValue = (lastToken: TokenType, sortingOption: SortingOptionTypeName): string | undefined => {
  switch (sortingOption) {
    case 'idAsc':
    case 'idDesc':
      return lastToken.id;
    case 'rarityScoreAsc':
    case 'rarityScoreDesc':
      return lastToken.rarityScore.toString();
    default:
      break;
  }
};
