import React from 'react';
import Form from 'react-bootstrap/Form';

interface SortingProps {
  defaultValue: SortingOptionTypeName;
  sortingOptions: SortingOptionType[];
  onSortingChange: (option: SortingOptionTypeName) => void;
}

const Sorting: React.FC<SortingProps> = ({ defaultValue, sortingOptions, onSortingChange }) => {

  const handleSortingChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    console.log(event.target.value);
    onSortingChange(event.target.value as SortingOptionTypeName);
  };

  return (
    <Form.Select aria-label="Select sorting" onChange={handleSortingChange} defaultValue={defaultValue}>
      {
        sortingOptions.map((option, index) => (
          <option key={`sorting-option-${index}`} value={option.type}>{option.name}</option>
        ))
      }
    </Form.Select>
  )
};

export default Sorting;