import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const GetListedBanner = () => (
  <Container className="mb-5">
    <Row>
      <Col />
      <Col lg={8}>
        <h1 className="mt-5 text-center">List a collection on Rankings Tools</h1>
        <p className="text-center">List your NFT collection now on Rankings Tools for free and find the most unique items in your collection.</p>
      </Col>
      <Col />
    </Row>
  </Container>
);

export default GetListedBanner;