import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import RarityDistributionChart from './RarityDistributionChart';
import { useCollectionStore } from '../../../stores/CollectionStore';

const RarityDistributionPage = () => {
  const { collection } = useCollectionStore();
  const stats = collection?.rankingsStats;

  if (!stats) {
    return null;
  }

  return (
    <Container>
      <Row>
        <Col>
          <Card body>
            <Card.Title>Rarity distribution</Card.Title>
            <RarityDistributionChart rarityDistribution={stats.distribution} ranges={stats.ranges} />
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default RarityDistributionPage;
