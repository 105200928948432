import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Stack from 'react-bootstrap/Stack';
import Spinner from 'react-bootstrap/Spinner';
import { Link } from 'react-router-dom';

const RecentlyAddedSection = () => {
  const [collections, setCollections] = useState<TokenCollectionType[]>([]);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_SERVER_ENDPOINT}/v1/collection`)
      .then(response => response.json())
      .then(({ data }: { data: { collections: TokenCollectionType[] } }) => {
        if (data.collections) {
          setCollections(data.collections);
        }
      });
  }, []);

  if (collections.length === 0) {
    return null;
  }

  return (
    <Container>
      <Row>
        <Col>
          <h1 className="text-center mt-5 mb-4">Recently added collections</h1>
          <Card body>
            <Stack className="flex-wrap justify-content-center" direction="horizontal" gap={3}>
              {collections.map(collection => (
                <Card as={Link} to={`/collection/${collection.id}`} className="align-self-stretch text-decoration-none" style={{ width: '150px' }} key={`collection-${collection.id}`}>
                  <Card.Img variant="top" src={collection.image || 'https://via.placeholder.com/150'} />
                  <Card.Body>
                    <Card.Title className="fs-6">{collection.name}</Card.Title>
                    {
                      collection.status === 'Syncing' && (
                        <Card.Subtitle className="mb-2 text-muted fw-normal">
                          <span className="me-1">Syncing...</span>
                          <Spinner size="sm" animation="border" role="status" />
                        </Card.Subtitle>
                      )
                    }
                  </Card.Body>
                </Card>
              ))}
            </Stack>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default RecentlyAddedSection;
