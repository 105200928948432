import React, { useEffect, useState } from 'react';
import TokenCardContainer from './TokenCardContainer';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Pagination from 'react-bootstrap/Pagination';
import Filters from './Filters';
import Sorting from './Sorting';
import { useCollectionStore } from '../../../stores/CollectionStore';
import { observer } from 'mobx-react-lite';
import { useCallback } from 'react';
import { getLastItemValue } from '../../../utility';

interface PaginationItem {
  lastFid?: string;
  lastValue?: string;
  limit: number;
  startAfter: boolean;
}

const TokensSection: React.FC = observer(() => {
  const { collection, filters } = useCollectionStore();
  const pageTokensLimit = 50;
  const { sortingOptions } = collection || {};
  const defaultSortingOption = sortingOptions?.find(option => option.default)?.type || sortingOptions?.[0]?.type || 'idAsc';
  const [tokenCards, setTokenCards] = useState<TokenType[]>([]);
  const [selectedFilters, setSelectedFilters] = useState<string[]>([]);
  const [selectedSorting, setSelectedSorting] = useState<SortingOptionTypeName>(defaultSortingOption);
  const [showFiltersModal, setShowFiltersModal] = useState(false);
  const [currentPage, setCurrentPage] = useState<PaginationItem>({
    limit: pageTokensLimit,
    startAfter: true
  });
  const [paginationHistory, setPaginationHistory] = useState<PaginationItem[]>([])

  const handleOnSortingChange = (sorting: SortingOptionTypeName) => {
    setPaginationHistory([]);
    setCurrentPage({
      limit: pageTokensLimit,
      startAfter: true
    });
    setSelectedSorting(sorting);
  };

  const handleOnFiltersChange = (checked: boolean, key: string) => {
    setPaginationHistory([]);
    setCurrentPage({
      limit: pageTokensLimit,
      startAfter: true
    });

    if (checked) {
      setSelectedFilters((current) => [...current, key]);
    } else {
      setSelectedFilters(selectedFilters.filter(f => f !== key));
    }
    console.log(selectedFilters);
  };

  useEffect(() => {
    console.log('useEffect...');
    const data = {
      collection: collection?.id,
      sorting: selectedSorting,
      limit: currentPage.limit,
      filters: selectedFilters,
      lastFid: currentPage.lastFid,
      lastValue: currentPage.lastValue,
      startAfter: currentPage.startAfter
    };
    const requestObject: RequestInit = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    };

    fetch(`${process.env.REACT_APP_SERVER_ENDPOINT}/v1/token`, requestObject)
      .then(response => response.json())
      .then(({ status, message, data }) => {
        if (data) {
          setTokenCards(data);
        }
      })
      .catch(console.error)
  }, [selectedFilters, collection?.id, selectedSorting, currentPage]);

  if (!collection) {
    return null;
  }

  if (collection.status === 'Syncing') {
    return (
      <Container>
        <Row>
          <Col>
            <p>This collection is still being processed, the tokens will appear here shortly. Please come back later.</p>
          </Col>
        </Row>
      </Container>
    );
  }

  const lastToken = tokenCards[tokenCards.length - 1];
  const firstToken = tokenCards[0];

  const handleNextPageClick = useCallback(() => {
    setPaginationHistory([...paginationHistory, {
      limit: pageTokensLimit,
      lastFid: firstToken.fid,
      lastValue: getLastItemValue(firstToken, selectedSorting),
      startAfter: false
    }]);

    setCurrentPage({
      limit: pageTokensLimit,
      lastFid: lastToken.fid,
      lastValue: getLastItemValue(lastToken, selectedSorting),
      startAfter: true
    });
  }, [lastToken, firstToken, paginationHistory, selectedSorting]);

  const handlePreviousPageClick = useCallback(() => {
    const page = paginationHistory.pop();
    if (page) {
      setPaginationHistory(paginationHistory);
      setCurrentPage(page);
    }
  }, [paginationHistory]);

  const handleFirstPageClick = useCallback(() => {
    setPaginationHistory([]);
    setCurrentPage({
      limit: pageTokensLimit,
      startAfter: true
    });
  }, []);

  return (
    <>
      <Container>
        <Row>
          <Col className="d-none d-md-block" sm={12} md={4} lg={3} >
            <Filters filters={filters || []} onFiltersChange={handleOnFiltersChange} />
          </Col>
          <Col sm={12} md={8} lg={9}>
            <Button className="w-100 mb-4 d-inline-block d-md-none" onClick={() => setShowFiltersModal(true)}>Filters</Button>
            <Row className="mb-4">
              <Col sm="12" md="8">
                <p className="m-0 h-100 d-flex align-items-center">We do not provide any financial or investment advice.</p>
              </Col>
              <Col className="d-none d-md-block" md="4">
                <Sorting sortingOptions={collection.sortingOptions} onSortingChange={handleOnSortingChange} defaultValue={defaultSortingOption} />
              </Col>
            </Row>
            <TokenCardContainer tokens={tokenCards} />
            <Pagination className="justify-content-center my-5">
              <Pagination.First disabled={!paginationHistory.length} onClick={handleFirstPageClick} />
              <Pagination.Prev disabled={!paginationHistory.length} onClick={handlePreviousPageClick} />
              <Pagination.Next disabled={tokenCards.length < pageTokensLimit} onClick={handleNextPageClick} />
            </Pagination>
          </Col>
        </Row>
      </Container>

      <Modal show={showFiltersModal} fullscreen onHide={() => setShowFiltersModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Filter & Sort</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h3 className="mb-2">Sort by</h3>
          <Sorting sortingOptions={collection.sortingOptions} onSortingChange={handleOnSortingChange} defaultValue={defaultSortingOption} />
          <hr />
          <Filters filters={filters || []} onFiltersChange={handleOnFiltersChange} />
        </Modal.Body>
      </Modal>
    </>
  );
});

export default TokensSection;